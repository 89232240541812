<template>
  <div>
    <p class="title is-4">Huidige Netwerk Dienstverlening</p>

    <p>
      De gebruikers van het AFM-netwerk zijn facilitaire systemen en toepassingen. Concrete voorbeelden (niet
      limitatief) zijn:
      Over welk type toestel gaat het en in welk domein situeert het zich (meerdere opties mogelijk)

    </p>

    <section>
      <b-field horizontal label="Generiek">
        <b-checkbox v-model="content.generieke_domeinen" native-value="toestellen_werkplekken" expanded>
          Gebruikerstoestellen werkplekken (PC, laptop, IP telefoon, ...)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="randapparatuur_werkplekken" expanded>
          Randapparatuur bij werkplekken: printers, scanners, plotters, frankeermachines, ..
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="collaboratie" expanded>
          Collaboratie, videoconferentie / vergaderen, geluidsinstallaties, ...
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="toegangscontrole" expanded>
          Toegangscontrole en tijdsregistratie systemen
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="ticketing" expanded>
          Ticketing systemen
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="fysieke_beveiliging" expanded>
          Fysieke beveiliging (bewakingssystemen/inbraakpreventie, camerabewaking, alarmsysteem, brand, slagboom ...)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="gebouw_beheersing" expanded>
          Gebouw- & energiebeheersing (sturing, liften, regelaars, dataloggers, metering, alerting, ..)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="telefonie" expanded>
          Klassieke telefonie
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="digital_signage" expanded>
          Kiosk, digital signage (infoborden/mediaschermen)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="iot" expanded>
          IOT gerelateerde toestellen (sensor gebaseerde toestellen, slimme automaten, slimme kasten ...)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="kassa" expanded>
          kassasystemen, betaalterminal, etc. (vb resto)
        </b-checkbox>
      </b-field>
      <b-field horizontal>
        <b-checkbox v-model="content.generieke_domeinen" native-value="edge_computing" expanded>
          Edge Computing (diverse domeinen)
        </b-checkbox>
      </b-field>
      <br>
      <b-field horizontal label="Specifiek">
        <b-checkbox v-model="content.specifieke_domeinen_active" expanded>
          Specifieke toepassing (vb camera voor observatie slechtvalken)
        </b-checkbox>
      </b-field>
      <b-field horizontal v-if="content.specifieke_domeinen_active">
        <b-input v-model="content.specifieke_domeinen_text" placeholder="Beschrijf:" expanded>
          Specifieke toepassing (vb camera voor observatie slechtvalken)
        </b-input>
      </b-field>
      <br>

      <b-notification type="is-primary is-light">Het is niet toegelaten om ‘netwerkapparatuur’ zonder voorafgaandelijke
        goedkeuring op te nemen in het netwerk. Onder netwerkapparatuur wordt verstaan (niet limitatief): routers,
        firewalls, switches, wireless Access Points; VPN devices, etc.
      </b-notification>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "algemene-gegevens",
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        generieke_domeinen: []
      }

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._1_device_details) {
              this.content = JSON.parse(JSON.stringify(result._1_device_details))
            }
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_1_device_details: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    }

  }
}
</script>

<style scoped>

</style>