<template>
  <div>
    <section>
      <p class="title is-4">VO NAC - Authenticatie</p>


      <p>Toegang op het netwerk van de VO vereist Netwerk Access Controle (NAC). Hoe dienen de toestellen te worden
        gekoppeld?</p>

      <b-message type="is-primary is-light">De Vlaamse Overheid heeft NAC uitgerold in alle gebouwen (in beheer van
        HFB) op basis van 802.1x authenticatie. Het ondersteunen van 802.1x en meer specifiek EAP-TLS wordt dus opgelegd
        als vereiste om te mogen connecteren op het netwerk van de VO en om automatisch toegang te krijgen tot de
        voorziene netwerk zones en segmenten (VLAN – Virtual LAN).
      </b-message>

      <b-field>
        <b-radio v-model="content.option" native-value="eap_tls" expanded>
          Toestel ondersteunt <b>802.1x – EAP-TLS</b>
        </b-radio>
      </b-field>

      <div class="box pl-5" v-if="content.option === 'eap_tls'">
        <div class="content">
          <p class="is-size-6">De toestellen dienen hiertoe TLS 1.0/1.1/1.2 (bij voorkeur ook TLS 1.3) te
            ondersteunen</p>
          <p class="is-size-6">Om apparatuur via 802.1x toegang te geven tot het netwerk zal door de VO een
            ‘certificaat’ gebaseerde
            authenticatie en autorisatie worden opgelegd (EAP-TLS). De eindapparatuur dient hiertoe voorzien te worden
            van
            een device certificaat</p>
          <p class="is-size-6">Alle gebruikte certificaten (device, intermediate, root, ..) dienen gebaseerd te zijn op
            het X.509 raamwerk
            Het device certificaat op het toestel mag geen ‘self-signed’ certificaat zijn maar dient steeds gesigneerd
            te
            zijn door een certificeringsinstantie. In alle onderstaande cases dient het (root) certificaat van de CA en
            bijhorende intermediate(s) kenbaar gemaakt te worden aan het VO Netwerk Team zodat deze in de VO Netwerk
            Authenticatie server (Cisco ISE) kan geïmporteerd worden zodat het device certificaat op betrouwbaarheid kan
            gevalideerd worden door de Cisco ISE. Het toestel kan het servercertificaat van de VO Authenticatie server
            (Cisco ISE) verifiëren op basis van een lijst met vertrouwde certificaten op het toestel waarbij het root
            certificaat (of certificaat van de intermediate CA) van VO Authenticatie server werd geïmporteerd. Dit
            certificaat kan worden aangeleverd door het VO Netwerk team. Het toestel dient het certificaat op geldigheid
            te controleren conform de gangbare richtlijnen voor certificaat validatie.
          </p>
        </div>
        <hr>

        <b-field>
          <b-radio v-model="content.eap_tls.option" native-value="eigen_certificaat" expanded>
            aanvrager/entiteit zorgt voor ‘eigen’ device certificaten en provisionering op toestel
          </b-radio>
        </b-field>

        <div class="pl-5" v-if="content.eap_tls.option === 'eigen_certificaat'">
          <b-message>
            Het device certificaat is bij voorkeur geen intern ‘Manufactured Installed Certificate’ en dit uit
            veiligheidsoverwegingen: dit betreft een bulit-in certificaat - signed door een CA van de fabrikant - dat
            werd geïnstalleerd op het toestel ten tijde van de fabricage van het toestel (meestal op basis van het
            unieke MAC adres). Het gebruik ‘Manufactured Installed Certificate’ (MIC) zal alleen maar worden
            toegelaten indien geen andere optie mogelijk is.
          </b-message>
          <br>
        </div>

        <b-field>
          <b-radio v-model="content.eap_tls.option" native-value="vo_pki_certificaat" expanded>
            aanvrager gebruikt VO PKI certifiaten en provisionering op toestel
          </b-radio>
        </b-field>

        <div class="pl-5" v-if="content.eap_tls.option === 'vo_pki_certificaat'">
          <b-message type="is-primary">
            <div class="content">
              <p>
                Mogelijke aanpak is om CSR op toestel te laten genereren om vervolgens te laten tekenen door de
                betreffende CA van de VO. Een CSR bevat informatie (bv. Common Name, Organisatie, etc.) die de (CA) dan
                gaat gebruiken om het device certificaat te creëren. De CSR bevat ook de public key die deel uitmaakt
                van het device certificaat en wordt ondertekend met de overeenkomstige private key. Teneinde het toestel
                op de juiste manier te kunnen segmenteren zal de VO Netwerk Authenticatie server (Cisco ISE) moeten
                kunnen differentiëren op basis van velden in het device certificaat.
                Vooreerst dient het certificaat uniek te zijn zodat het ook individueel kan gerevoceerd worden indien
                nodig. Het device certificaat kan uniek gemaakt worden door de ‘hostname’ of DNSname van het toestel te
                gebruiken als Common Namen (CN) van het toestel. Om overlappende hostnames te vermijden over de diverse
                VO entiteiten heen, dient de volgende nomenclatuur te worden gerespecteerd:
              </p>

              <p>
                CN =
                <b-tag>device-ID</b-tag>
                .
                <b-tag>leverancier</b-tag>
                .
                <b-tag>segment</b-tag>
                .
                <b-tag>netwerk</b-tag>
                .vlaanderen.be
              </p>
              Waarbij

              <ul>
                <li>
                  <b-tag>device-ID</b-tag>
                  = unieke identifier van het toestel;

                </li>
                <li>
                  <b-tag>leverancier</b-tag>
                  = leverancier van het toestel (Nec, Poly, Yea,…) ;

                </li>
                <li>
                  <b-tag>segment</b-tag>
                  = VLAN id of SDN-tag;

                </li>
                <li>
                  <b-tag>netwerk</b-tag>
                  = virtueel netwerk (vb AFM, GID, Entiteit netwerk, …) .
                  OU = dient de naam van de entiteit of organisatie te zijn die eigenaar is van het certificaat (en
                  dus ook beschikt over de ‘private key’).
                </li>
              </ul>

            </div>
            <p>
              Het device certificaat is bij voorkeur geen intern ‘Manufactured Installed Certificate’ en dit uit
              veiligheidsoverwegingen: dit betreft een bulit-in certificaat - signed door een CA van de fabrikant - dat
              werd geïnstalleerd op het toestel ten tijde van de fabricage van het toestel (meestal op basis van het
              unieke MAC adres). Het gebruik ‘Manufactured Installed Certificate’ (MIC) zal alleen maar worden
              toegelaten indien geen andere optie mogelijk is.
            </p>

          </b-message>
          <br>
        </div>

        <b-field>
          <b-radio v-model="content.eap_tls.option" native-value="eigen_aangekocht_certificaat" expanded>
            aanvrager gebruikt eigen aangekochte publieke certificaten en op toestel.
          </b-radio>
        </b-field>

        <b-field>
          <b-radio v-model="content.eap_tls.option" native-value="ip_telefoon" expanded>
            Het betreft IP telefoon met achterliggende desktop/laptop dat op het toestel wordt aangesloten:
          </b-radio>
        </b-field>

        <div class="pl-5" v-if="content.eap_tls.option === 'ip_telefoon'">
          <b-message type="is-primary">
            <div class="content">
              <p>De VO switches zijn standaard geconfigureerd voor multi-domain op één switchpoort. D.w.z. dat op
                dezelfde ethernet poort twee 802.1x authenticaties kunnen worden afgehandeld, één voor data (pc/laptop)
                en één voor voice (IP telefoon).</p>
              <p>Gezien IP telefoontoestellen ‘media’ trafiek genereren wordt bijkomend functionaliteit gevraagd mbt
                LLDP/CDP en QoS markering. LLDP (of CDP) zal gebruikt worden voor het uitwisselen van device
                gerelateerde informatie tussen de VO LAN switch en de IP telefoon. De IP telefoon zal op periodiek
                interval informatie doorsturen naar de switch (type telefoon, etc.) Omgekeerd zal de VO switch (na een
                succesvolle 802.1x authenticatie) via LLDP/CDP bepalen welke (initiële) VLAN encapsulatie de IP telefoon
                dient te gebruiken. Vandaag wordt namelijk binnen het VO netwerk naast het werkplekken productie netwerk
                een apart VLAN gebruikt voor IP telefoons (voice VLAN). In de toekomst zal er binnen de HFB beheerde
                werkplekken gestandaardiseerd worden op één VLAN voor zowel data als voice. De IP telefoon zal in staat
                zijn om (802.1Q) VLAN tagging te ondersteunen met native VLAN voor de achterliggende PC/laptop. De IP
                telefoon dient in staat te zijn de media trafiek te markeren op basis van de DSCP normering (bijv. DSCP
                EF voor voice trafiek). Ter informatie, bij multi-domain maakt ‘data’ gebruik van de native VLAN
                (untagged) en ‘voice’ van de (via LLDP aangeleerde) voice VLAN. In eerste instantie zijn dit geïsoleerde
                dummy vlans ter afwikkeling van het authenticatieproces. Na succesvolle authenticatie zullen deze
                gelinkt worden aan twee andere VLANs op basis van return-attributen die de switch zal leren in zijn
                communicatie met de achterliggende VO Netwerk Authenticatie server. Het zullen uiteindelijk deze twee
                vlans zijn die verder gerouteerd zullen worden naar de rest van het VO netwerk. De achterliggende
                Netwerk Authenticatie server server zal op zich geen gebruikers/groupen/devices hosten maar beroep doen
                op achterliggende gebruikers directories (AD/LDAP) en databases, bijv. voor MAB (LDAP). MAB in
                combinatie met multi-domain op de VO switches is niet ondersteund /gevalideerd.</p>
            </div>
          </b-message>

          <b-field>
            <b-checkbox v-model="content.eap_tls.ip_telefoon_options" native-value="cdp_lldp" expanded>
              IP telefoon ondersteunt CDP/LLDP voor VLAN assignement voor voice (data van PC/laptop untagged)
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="content.eap_tls.ip_telefoon_options" native-value="802.1x" expanded>
              IP telefoon ondersteunt 802.1x EAP-pol logoff voor desktop/laptop
            </b-checkbox>
          </b-field>
          <br>
        </div>

        <b-field>
          <b-radio v-model="content.eap_tls.option" native-value="vo_pki_scep_certificaat" expanded>
            <span class="has-text-grey-light">aanvrager gebruikt VO PKI certifiaten en provisionering op toestel (roadmap)</span>
          </b-radio>
        </b-field>

        <div class="pl-5" v-if="content.eap_tls.option === 'vo_pki_scep_certificaat'">
          <b-message type="is-primary">
            <div class="content">
              <p>
                Het proces van initiële provisionering en hernieuwen van deze (device en server) certificaten kan op een
                aantal manieren:
              </p>

              <ul>
                <li>
                  device certificaten genereren en laten tekenen (VO CA, andere CA) en manueel per toestel importeren en
                  activeren/herladen via webinterface (niet aanbevolen);

                </li>
                <li>
                  device certificaten genereren en laten signen (VO CA, andere CA) en op geautomatiseerde wijze
                  importeren activeren/herladen. Dit zou doorgaans kunnen via het dedicated device management platform
                  van de leverancier zelf;

                </li>
                <li>
                  volledig dynamisch op basis van ‘Simple Certificate Enrollment Protocol (SCEP)’ (aanbevolen
                  oplossing).
                </li>
              </ul>

            </div>
            <p>
              De aanbeveling gaat hieruit om het proces van device certificaat creatie/provisonering/renewal zo
              geautomatiseerd en gestandaardiseerd als mogelijk te laten verlopen. Het gebruik van SCEP is de aanbevolen
              optie. SCEP zit momenteel in scope van het VO PKI migratie project VOICA (als plugin) om het momenteel
              manuele beheer van de certificaten op geconnecteerde toestellen zo breed mogelijk te kunnen automatiseren.
            </p>

          </b-message>
          <br>
        </div>


        <hr>


      </div>
      <b-field>
        <b-radio v-model="content.option" native-value="geen_eap_tls" expanded>
          Toestel ondersteunt <b>géén 802.1x – EAP-TLS</b>
        </b-radio>
      </b-field>

      <div class="pl-5" v-if="content.option === 'geen_eap_tls'">
        <b-field>
          <b-radio v-model="content.geen_eap_tls.option" native-value="user/pass" expanded>
            Toestel ondersteunt 802.1x - Username/password (PEAP-MSCHAP v2)
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="content.geen_eap_tls.option" native-value="hfb_radius" expanded>
            VO-HFB doet afhandeling authenticatie op basis van HFB RADIUS
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="content.geen_eap_tls.option" native-value="eigen_radius" expanded>
            Aanvrager doet afhandeling authenticatie op basis van <i>eigen</i> RADIUS (realm)
          </b-radio>
        </b-field>
        <div class="pl-5" v-if="content.geen_eap_tls.option === 'eigen_radius'">
          <b-field grouped message="RADIUS-secret niet in dit document plaatsen">
            <b-field label="Omgeving (partner, vonet,...">
              <b-input v-model="content.geen_eap_tls.eigen_radius.omgeving"></b-input>
            </b-field>
            <b-field v-model="content.geen_eap_tls.eigen_radius.ip" label="RADIUS-IP" expanded>
              <b-input></b-input>
            </b-field>
            <b-field v-model="content.geen_eap_tls.eigen_radius.port" label="RADIUS-Port" expanded>
              <b-input type="number"></b-input>
            </b-field>
          </b-field>
        </div>
        <br>
      </div>

      <b-field>
        <b-radio v-model="content.option" native-value="mac_auth" expanded>
          MAB (Mac Authentication Bypass) want toestel ondersteunt geen 802.1x.
        </b-radio>
      </b-field>

      <div class="pl-5" v-if="content.option === 'mac_auth'">

        <b-message type="is-primary is-light">
          Voor de usecases waarbij geen 8021.x mogelijk is, zal MAB (Mac Authentication Bypass) worden toegestaan
        </b-message>

        <b-table :data="content.mac_auth.devices">

          <b-table-column label="MAC-Address toestel" v-slot="props">
            {{ props.row.mac_addr }}
          </b-table-column>

          <b-table-column label="Serienummer toestel" v-slot="props">
            {{ props.row.ser }}
          </b-table-column>

          <b-table-column v-slot="props">
            <b-button @click="remove(props.index)" icon-left="delete" type="is-danger"></b-button>
          </b-table-column>

          <template slot="empty">
            <small>Gebruik de onderstaande balk om mac adressen toe te voegen.</small>
          </template>

          <template slot="footer">
            <th class="is-hidden-mobile">
              <b-field>
                <b-input placeholder="MAC-Address" v-model="currentmac"></b-input>

              </b-field>
            </th>
            <th class="is-hidden-mobile">
              <b-field>
                <b-input placeholder="Serienummer" v-model="currentser"></b-input>
              </b-field>
            </th>
            <th class="is-hidden-mobile">
              <div class="buttons right">
                <b-button @click="add" icon-left="plus-circle" type="is-success"></b-button>
              </div>
            </th>
          </template>
        </b-table>
        <br>

        <b-field>
          <b-checkbox class="has-text-grey" v-model="content.mac_auth.custom_selected" expanded>
            MAB kan aangevuld worden met specifieke patronen in attributen binnen gekende netwerk protocollen in gebruik
            door het toestel (device fingerprinting/profiling), roadmap
          </b-checkbox>
        </b-field>

        <div v-if="content.mac_auth.custom_selected">
          <b-table :data="content.mac_auth.custom_data">
            <b-table-column label="Netwerk protocol" v-slot="props">
              {{ props.row.proto }}
            </b-table-column>
            <b-table-column label="Type attribuut" v-slot="props">
              <b-input v-model="props.row.type"></b-input>
            </b-table-column>
            <b-table-column label="Waarde" v-slot="props">
              <b-input v-model="props.row.value"></b-input>
            </b-table-column>
          </b-table>
        </div>

        <br>


      </div>

      <b-field>
        <b-radio class="has-text-grey" v-model="content.option" native-value="profiling_only" expanded>
          Authenticatie alleen op basis van profiling/fingerprinting, roadmap
        </b-radio>
      </b-field>

      <div v-if="content.option === 'profiling_only'">
        <b-table :data="content.profiling.custom_data">
          <b-table-column label="Netwerk protocol" v-slot="props">
            {{ props.row.proto }}
          </b-table-column>
          <b-table-column label="Type attribuut" v-slot="props">
            <b-input v-model="props.row.type"></b-input>
          </b-table-column>
          <b-table-column label="Waarde" v-slot="props">
            <b-input v-model="props.row.value"></b-input>
          </b-table-column>
        </b-table>


      </div>

      <br>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "authenticatie",
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        option: "",
        eap_tls: {
          option: "",
          ip_telefoon_options: []
        },
        geen_eap_tls: {
          option: "",
          eigen_radius: {},
        },
        mac_auth: {
          devices: [],
          custom_selected: false,
          custom_data: [
            {
              proto: 'SNMP',
              type: '',
              value: ''
            },
            {
              proto: 'CDP/LDP',
              type: '',
              value: ''
            },
            {
              proto: 'DNS',
              type: '',
              value: ''
            },
            {
              proto: 'HTTP',
              type: '',
              value: ''
            },
            {
              proto: 'Netflow',
              type: '',
              value: ''
            },
            {
              proto: 'Andere',
              type: '',
              value: ''
            }

          ]
        },
        profiling: {
          custom_data: [
            {
              proto: 'SNMP',
              type: '',
              value: ''
            },
            {
              proto: 'CDP/LDP',
              type: '',
              value: ''
            },
            {
              proto: 'DNS',
              type: '',
              value: ''
            },
            {
              proto: 'HTTP',
              type: '',
              value: ''
            },
            {
              proto: 'Netflow',
              type: '',
              value: ''
            },
            {
              proto: 'Andere',
              type: '',
              value: ''
            }

          ]
        }
      },

      currentmac: "",
      currentser: ""

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._authenticatie) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._authenticatie)))
            }
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_authenticatie: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })

    },

    add() {
      this.content.mac_auth.devices.push({
        mac_addr: this.currentmac,
        ser: this.currentser
      })
      this.currentmac = ""
      this.currentser = ""
    },
    remove(index) {
      if (index > -1) {
        this.content.mac_auth.devices.splice(index, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>