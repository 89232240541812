<template>
  <b-autocomplete
      v-model="findbuilding"
      open-on-focus
      :data="filteredDataObj"
      placeholder="Klik om op gebouw of 3LC te zoeken"
      field="name"
      @select="select"
      max-height="400"
  >

    <template slot-scope="props">
      <div class="media">
        <div class="media-left">
          <b-icon icon="office-building-outline">
          </b-icon>
          <p>{{ props.option.code }}</p>
        </div>
        <div class="media-content">
          {{ props.option.name }}
          <br>
          <small>
            {{ props.option.address }},
            {{ props.option.city }}
          </small>
        </div>
      </div>
    </template>


  </b-autocomplete>
</template>
<script>
export default {
  name: "building-selector",

  data() {
    return {
      findbuilding: "",
      currentbuilding: "",
    }
  },

  mounted() {
    this.$store.dispatch("fetchAllBuildings")
  },

  computed: {
    buildings() {
      return this.$store.state.buildings
    },
    filteredDataObj() {
      return this.buildings.filter((option) => {
        let str = option.name + " " + option.code + " " + option.address + " " + option.city
        return str
            .toString()
            .toLowerCase()
            .indexOf(this.findbuilding.toLowerCase()) >= 0
      })

    },
  },

  methods: {

    select(option) {
      this.currentbuilding = option ? option.code : null
      this.$emit('select', option)
    }
  }

}


</script>