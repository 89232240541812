<template>
  <div>
    <section>
      <p class="title is-4">Netwerkdiensten</p>

      <p>Binnen de dienstverlening van netwerken worden standaard een aantal aantal <i>‘technical services’</i>
        aangeboden zoals DNS, DHCP, NTP, etc. Een overzicht:</p>

      <b-field label=" Standaard voorzien:">

        <div class="content pl-5">
          <dl style="list-style-type:none;">
            <li>
              <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
              <span>
                <b>Forwarding DNS</b> voor communicatie naar het Internet is een standaard voorziening. Deze zal standaard
                via DHCP worden meegegeven. Voor statische instellingen kan de volgende URL worden opgegeven:
                <b>proxy.vlaanderen.be</b>
              </span>
            </li>
            <li>
              <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
              <span>
                <b>	NTP</b> is een standaard voorziening   Voor statische instellingen kan de volgende URL worden
                opgegeven: <b>ntp.vlaanderen.be</b>
              </span>
            </li>
            <li>
              <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
              <span><b>DHCP mogelijkheden:</b></span>
            </li>
          </dl>
        </div>
      </b-field>

      <div class="pl-10">
        <b-field>
          <b-radio v-model="content.dhcp.option" native-value="standaard" expanded>
            Standaard, VO-HFB doet afhandeling DHCP van centrale HFB IPAM
          </b-radio>
        </b-field>

        <div class="box pl-5" v-if="content.dhcp.option === 'standaard'">
          <b-field>
            <b-checkbox v-model="content.dhcp.reservaties.selected" expanded>
              DHCP Reservaties
            </b-checkbox>
          </b-field>

          <div class="box pl-5" v-if="content.dhcp.reservaties.selected">
            <b-table :data="content.dhcp.reservaties.data">

              <b-table-column label="MAC-Address toestel" v-slot="props">
                {{ props.row.mac }}
              </b-table-column>

              <b-table-column label="IP adres">
                <small class="has-text-grey">Wordt toegekend door HFB</small>
              </b-table-column>

              <b-table-column v-slot="props">
                <b-button @click="removeRes(props.index)" icon-left="delete" type="is-danger"></b-button>
              </b-table-column>

              <template slot="empty">
                <small>Gebruik de onderstaande balk om mac adressen toe te voegen.</small>
              </template>

              <template slot="footer">
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="MAC-Address" v-model="currentmac"></b-input>

                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input disabled placeholder="IP adres"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <div class="buttons right">
                    <b-button @click="addRes" icon-left="plus-circle" type="is-success"></b-button>
                  </div>
                </th>
              </template>
            </b-table>
            <br>
          </div>

          <b-field>
            <b-checkbox v-model="content.dhcp.extra_options.selected" native-value="standaard" expanded>
              Extra benodigde DHCP opties
            </b-checkbox>
          </b-field>

          <div class="box pl-5" v-if="content.dhcp.extra_options.selected">
            <b-table :data="content.dhcp.extra_options.data">

              <b-table-column label="OPTIONS CODE" v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column label="OPTIONS TYPE" v-slot="props">
                {{ props.row.type }}
              </b-table-column>

              <b-table-column label="OPTIONS WAARDE" v-slot="props">
                {{ props.row.value }}
              </b-table-column>

              <b-table-column label="VLAN" v-slot="props">
                {{ props.row.vlan }}
              </b-table-column>

              <b-table-column v-slot="props">
                <b-button @click="removeOpt(props.index)" icon-left="delete" type="is-danger"></b-button>
              </b-table-column>

              <template slot="empty">
                <small>Gebruik de onderstaande balk om opties toe te voegen.</small>
              </template>

              <template slot="footer">
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="OPTIONS CODE" v-model="option.code"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="OPTIONS TYPE" v-model="option.type"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="OPTIONS Waarde" v-model="option.value"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="VLAN" v-model="option.vlan"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <div class="buttons right">
                    <b-button @click="addOpt" icon-left="plus-circle" type="is-success"></b-button>
                  </div>
                </th>
              </template>
            </b-table>
            <br>
          </div>

        </div>

        <b-field>
          <b-radio v-model="content.dhcp.option" native-value="statisch" expanded>
            geen DHCP, er wordt statisch gewerkt. Alleen mogelijk voor ‘dedicated’ VLANs
          </b-radio>
        </b-field>

        <b-field>
          <b-radio v-model="content.dhcp.option" native-value="eigen_server" expanded>
            Aanvrager doet afhandeling DHCP op basis van ‘eigen’ DHCP server (DHCP relay door HFB). Alleen mogelijk voor
            dedicated VLANs
          </b-radio>
        </b-field>

        <div class="box pl-5" v-if="content.dhcp.option === 'eigen_server'">
          <b-field grouped>
            <b-field label="Omgeving (Partner, VONET,...)">
              <b-input v-model="content.dhcp.eigen_server_omgeving"></b-input>
            </b-field>
            <b-field label="IP DHCP van Entiteit" expanded>
              <b-input v-model="content.dhcp.eigen_server_ip_dhcp"></b-input>
            </b-field>
          </b-field>
        </div>

      </div>

      <br>

      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>

export default {
  name: "netwerkdiensten",
  components: {},
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        dhcp: {
          option: "",
          reservaties: {
            selected: false,
            data: []
          },
          extra_options: {
            selected: false,
            data: []
          }
        },

      },

      currentmac: "",

      // New entry
      option: {
        code: null,
        type: null,
        value: null,
        vlan: null
      }

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._netwerkdiensten) {
               Object.assign(this.content, JSON.parse(JSON.stringify(result._netwerkdiensten)))
            }
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_netwerkdiensten: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })

    },

    addRes() {
      this.content.dhcp.reservaties.data.push({mac: this.currentmac})
      this.currentmac = ""
    },
    removeRes(index) {
      if (index > -1) {
        this.content.dhcp.reservaties.data.splice(index, 1);
      }
    },

    addOpt() {
      this.content.dhcp.extra_options.data.push(this.option)
      this.option = {}
    },
    removeOpt(index) {
      if (index > -1) {
        this.content.dhcp.extra_options.data.splice(index, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>