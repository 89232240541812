<template>
  <div>
    <section>
      <p class="title is-4">Hoeveelheid en locatie van aansluiting</p>
      <p>Op welke locatie(s) dient het/de toestel(len) geconnecteerd te worden?</p>

      <b-collapse
          class="card"
          animation="slide">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button">
          <p class="card-header-title pb-0">
            Aansluiting binnen VO gebouw
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <b-field label="Op welke locatie(s) dient het/de toestel(len) geconnecteerd te worden?">
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_gebouwen" expanded>
                Alle VO gebouwen (toestellen dienen VO breed te kunnen worden uitgerold)
              </b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_247" expanded>
                Alle 24/7 (Musea, VCET, jeugdinstellingen, ...)
              </b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_vac" expanded>
                Alle VAC’s
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_kabinetten" expanded>
                Alle kabinetten
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_s1" expanded>
                Alle S1 sites
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_s2" expanded>
                Alle S2 sites
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_s3" expanded>
                Alle S3 sites
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_s4" expanded>
                Alle S4 sites
              </b-checkbox>
              <b-checkbox v-model="content.aansluiting_binnen_vo.options" native-value="alle_s5" expanded>
                Alle S5 sites
              </b-checkbox>
            </b-field>
            <br>

            <b-image :src="require(`@/assets/aansluiting_vo.svg`)"></b-image>


            <b-field>
              <b-checkbox v-model="content.aansluiting_binnen_vo.custom_building" expanded>
                Specifieke locatie, selecteer 1 of meerdere
              </b-checkbox>
            </b-field>
            <!--       <b-field  label="Aantal">-->
            <!--        <b-numberinput v-model="content.aansluiting_binnen_vo.aantal"  expanded>-->
            <!--        </b-numberinput>-->
            <!--      </b-field>-->

            <b-table v-if="content.aansluiting_binnen_vo.custom_building" :data="content.custom_binnen_vo">

              <b-table-column label="Gebouw" width="75%" v-slot="props">
                <b>{{ buildingDetails(props.row.code).code }}</b> | {{ buildingDetails(props.row.code).name }}
              </b-table-column>

              <b-table-column label="Aantal" numeric v-slot="props">
                {{ props.row.amount }}
              </b-table-column>

              <b-table-column v-slot="props">
                <b-button @click="remove(props.row.code)" icon-left="delete" type="is-danger"></b-button>
              </b-table-column>

              <template slot="empty">
                <small>Gebruik de onderstaande balk om gebouwen op te zoeken.</small>
              </template>

              <template slot="footer">
                <th class="is-hidden-mobile" style="width:40px">
                  <b-field>
                    <b-autocomplete
                        v-model="findbuilding"
                        open-on-focus
                        :data="filteredDataObj"
                        placeholder="Klik om op gebouw of 3LC te zoeken"
                        field="name"
                        @select="option => (currentbuilding = option.code)"
                        max-height="400"
                    >

                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-left">
                            <b-icon icon="office-building-outline">
                            </b-icon>
                            <p>{{ props.option.code }}</p>
                          </div>
                          <div class="media-content">
                            {{ props.option.name }}
                            <br>
                            <small>
                              {{ props.option.address }},
                              {{ props.option.city }}
                            </small>
                          </div>
                        </div>
                      </template>


                    </b-autocomplete>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <b-field>
                    <b-input placeholder="Aantal" v-model="currentamount"></b-input>
                  </b-field>
                </th>
                <th class="is-hidden-mobile">
                  <div class="buttons right">
                    <b-button @click="add" icon-left="plus-circle" type="is-success"></b-button>
                  </div>
                </th>
              </template>
            </b-table>
          </div>
        </div>
      </b-collapse>

      <b-collapse
          class="card"
          animation="slide">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button">
          <p class="card-header-title pb-0">
            Aansluiting binnen on-prem VO Datacenter
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-notification type="is-primary is-light">Servers of servertoepassingen (met uitzondering van Edge Computing)
            horen niet thuis in de VO gebouwen, zij
            kunnen opgenomen worden in één van de VO datacenter.
          </b-notification>

          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_datacenter.vop_dc_nos.selected" expanded>
                VOP-DC Nossegem NMC4
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_datacenter.vop_dc_nos.selected"
                       v-model="content.aansluiting_datacenter.vop_dc_nos.amount" required
                       placeholder="Aantal"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_datacenter.vop_dc_ant.selected" expanded>
                VO-DC Antwerpen
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_datacenter.vop_dc_ant.selected"
                       v-model="content.aansluiting_datacenter.vop_dc_ant.amount" required
                       placeholder="Aantal"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_datacenter.vpc_mech.selected" expanded>
                VPC Mechelen
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_datacenter.vpc_mech.selected"
                       v-model="content.aansluiting_datacenter.vpc_mech.amount" required placeholder="Aantal"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_datacenter.vpc_mech_overgang.selected" expanded>
                VPC Mechelen Overgangszone
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_datacenter.vpc_mech_overgang.selected"
                       v-model="content.aansluiting_datacenter.vpc_mech_overgang.amount" required
                       placeholder="Aantal"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_datacenter.managed_dc.selected" disabled expanded>
                Nieuw Managed DC (contract 2022)
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_datacenter.managed_dc.selected"
                       v-model="content.aansluiting_datacenter.managed_dc.amount" required
                       placeholder="Aantal"></b-input>
            </b-field>
          </b-field>


        </div>
      </b-collapse>

      <b-collapse
          class="card"
          animation="slide">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button">
          <p class="card-header-title pb-0">
            Dekking buiten VO gebouwen
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">

          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_extern.lpwan.selected" expanded>
                LPWAN (nog niet becontract 2022)
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_extern.lpwan.selected"
                       v-model="content.aansluiting_extern.lpwan.amount" required placeholder="Aantal"></b-input>
            </b-field>
          </b-field>

          <div v-if="content.aansluiting_extern.lpwan.selected">
            <b-field v-if="content.aansluiting_extern.lpwan.selected"
                     label="Beschrijf gewenste dekking (context, stedelijk / landelijk, regio’s, evt. gebouw-info)">
              <b-input v-model="content.aansluiting_extern.lpwan.description" type="textarea">

              </b-input>
            </b-field>

            <b-notification type="is-primary is-light">Abonnementen en SIM kaarten mbt 4G/5G of NB-IOT (Narrow-Band IOT)
              dienen aangevraagd te worden via
              het HFB Telecom contract.
            </b-notification>

            <hr>
          </div>


          <b-field grouped>
            <b-field expanded>
              <b-checkbox v-model="content.aansluiting_extern.outdoor_wifi.selected" expanded>
                Is er noodzaak voor Outdoor WIFI?
              </b-checkbox>
            </b-field>
            <b-field>
              <b-input v-if="content.aansluiting_extern.outdoor_wifi.selected"
                       v-model="content.aansluiting_extern.outdoor_wifi.amount" required placeholder="Aantal"></b-input>
            </b-field>
          </b-field>
          <div v-if="content.aansluiting_extern.outdoor_wifi.selected">
            <b-field
                label="Beschrijf verantwoording en gewenste dekking (context, stedelijk, landelijk, regio’s, evt. gebouw-info)">
              <b-input v-model="content.aansluiting_extern.outdoor_wifi.description" type="textarea">
              </b-input>
            </b-field>
          </div>

        </div>
      </b-collapse>


      <br>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "hoeveelheid-capacity",
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        aansluiting_binnen_vo: {
          options: [],
          aantal: ""
        },
        custom_binnen_vo: [],
        aansluiting_datacenter: {
          vop_dc_nos: {
            selected: false,
            amount: null,
          },
          vop_dc_ant: {
            selected: false,
            amount: null,
          },
          vpc_mech: {
            selected: false,
            amount: null,
          },
          vpc_mech_overgang: {
            selected: false,
            amount: null,
          },
          managed_dc: {
            selected: false,
            amount: null,
          },
        },
        aansluiting_extern: {
          lpwan: {
            selected: false,
            amount: null,
            description: null
          },
          outdoor_wifi: {
            selected: false,
            amount: null,
            description: null
          },
        }

      },
      buildings: [],

      findbuilding: "",
      currentbuilding: "",
      currentamount: 1,

    }
  },

  mounted() {
    this.fetchData()
  },
  computed: {
    filteredDataObj() {
      return this.buildings.filter((option) => {
        let str = option.name + " " + option.code + " " + option.address + " " + option.city
        return str
            .toString()
            .toLowerCase()
            .indexOf(this.findbuilding.toLowerCase()) >= 0
      })
    },


  },
  methods: {

    buildingDetails(code) {
      return this.buildings.filter((option) => {
        return option.code === code
      })[0]

    },

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._2_device_location) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._2_device_location)))
            }
          })

      this.$store.dispatch("fetchAllBuildings")
          .then(response => {
            this.buildings = response.data
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_2_device_location: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    },
    add() {
      this.content.custom_binnen_vo.push({
        code: this.currentbuilding,
        amount: this.currentamount
      })
      this.currentbuilding = ""
      this.findbuilding = ""
      this.currentamount = 1


    },
    remove(code) {
      var index = this.content.custom_binnen_vo.map(function (e) {
        return e.code;
      }).indexOf(code);
      if (index > -1) {
        this.content.custom_binnen_vo.splice(index, 1);
      }
    }

  }
}
</script>

<style scoped>

</style>