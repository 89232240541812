<template>
  <div>
    <section>
      <p class="title is-4">Informatiestromen</p>

      <p>
        Hoe lopen de informatiestromen van en naar de nieuwe toestellen?
      </p>

      <b-message type="is-primary is-light">
        Op basis van de ingevulde informatie hieronder zal het VO Netwerk Team in staat zijn om zelf een ACD te
        genereren teneinde de toestellen op een vlotte manier in het netwerk in te regelen.
      </b-message>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.intra_vo.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.intra_vo.selected"></b-checkbox>
            Intra-VO gebouwen
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">

          <inf-intra-vo :data="content.intra_vo.data"
                        v-on:update="content.intra_vo.data = $event"
          ></inf-intra-vo>

        </div>
      </b-collapse>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.internet.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.internet.selected"></b-checkbox>
            Naar Internet
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">

          <inf-internet :data="content.internet.data"
                        v-on:update="content.internet.data = $event"
          ></inf-internet>

        </div>
      </b-collapse>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.vo_datacenter.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.vo_datacenter.selected"></b-checkbox>
            Naar VO Datacenter
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">


        </div>
      </b-collapse>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.partner.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.partner.selected"></b-checkbox>
            Naar Partner via partneraansluiting
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">


        </div>
      </b-collapse>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.buiten_vo.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.buiten_vo.selected"></b-checkbox>
            Van buiten VO gebouwen naar toestel (inkomende flow)
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">


        </div>
      </b-collapse>


      <br>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
import InfIntraVo from "@/components/toestellen/flows/inf-intra-vo";
import InfInternet from "@/components/toestellen/flows/inf-internet";

export default {
  name: "informatiestromen",
  components: {InfInternet, InfIntraVo},
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        intra_vo: {
          selected: false,
          data: {}
        },
        internet: {
          selected: false,
          options: []
        },
        vo_datacenter: {
          selected: false,
          options: []
        },
        partner: {
          selected: false,
        },
        buiten_vo: {
          selected: false,
        },


      },


    }
  },

  mounted() {
    this.fetchData()
  },
  computed: {},

  methods: {


    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._3_koppeling) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._3_koppeling)))
            }
          })

    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_3_koppeling: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    },
  }
}
</script>

<style scoped>

</style>