<template>
  <div>
    <section>
      <p class="title is-4">Segmentatie</p>

      <p>Na succesvolle authenticatie zal het toestel toegang krijgen tot het netwerk van de VO. Segmentatie
        onderscheidt zich op Macroniveau (VRF/ Virtueel netwerk / VLAN) dewelke in de toekomst kan aangevuld worden door
        additionele Microsegmentatie (op basis van DNA SGT tag).</p>

      <b-image :src="require(`@/assets/segmentation.png`)"></b-image>

      <p>Met welk netwerk segment dient het toestel zich te connecteren?</p>


      <b-collapse
          class="card"
          animation="slide"
      >
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            Macrosegmentatie
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">

          <b-field>
            <b-checkbox v-model="content.macro.options" native-value="bestaand_netwerk" expanded>
              Bestaand gebruikersnetwerk (GID) op zowel berdraad als draadloos (SSID ‘Vlaanderen werk’), typisch voor
              gebruikerstoestellen. Standaard komen de toestellen in de Productie VLAN.
            </b-checkbox>
          </b-field>
          <div class="box pl-5" v-if="content.macro.options.includes('bestaand_netwerk')">
            <b-checkbox v-model="content.macro.options" native-value="gid_voice_vlan" expanded>
              Er wordt tevens gebruik gemaakt van de GID ‘Voice VLAN’ voor IP Telefoons (via 802.1x)
            </b-checkbox>
          </div>

          <b-field>
            <b-checkbox v-model="content.macro.options" native-value="afm" expanded>
              Netwerk voor technische toestellen (AFM)
            </b-checkbox>
          </b-field>
          <div class="box pl-5" v-if="content.macro.options.includes('afm')">
            <b-field>
              <b-checkbox v-model="content.macro.options" native-value="afm_bestaande_vlan" expanded>
                Er wordt tevens gebruik gemaakt van de GID ‘Voice VLAN’ voor IP Telefoons (via 802.1x)
              </b-checkbox>
            </b-field>
            <div class="box pl-5" v-if="content.macro.options.includes('afm_bestaande_vlan')">
              <p>Selecteer bestaande vlan:</p>

              <b-table :data="content.macro.vlan_data">
                <b-table-column label="Gebouw" width="55%" v-slot="props">
                  {{ props.row.building_code }}
                </b-table-column>
                <b-table-column label="VLAN-ID" numeric v-slot="props">
                  {{ props.row.vlan }}
                </b-table-column>
                <b-table-column label="Naam" v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column>
                  <template v-slot:header="{ column }">
                    <download-csv v-if="content.macro.vlan_data.length > 0" :data="content.macro.vlan_data"
                                  name="gid_voice_vlans.csv" :label="column">
                      <a>
                        <b-icon size="is-small" icon="download"></b-icon>
                      </a>
                    </download-csv>
                  </template>

                  <template v-slot="props">
                    <b-button @click="remove(props.index)" icon-left="delete" type="is-danger"></b-button>
                  </template>
                </b-table-column>

                <template slot="footer">
                  <th class="is-hidden-mobile" style="width:40px">
                    <b-field>
                      <building-selector @select="building => (entry.building_code = building.code)"/>
                    </b-field>
                  </th>
                  <th class="is-hidden-mobile">
                    <b-field>
                      <b-input placeholder="VLAN ID" v-model="entry.vlan"></b-input>
                    </b-field>
                  </th>
                  <th class="is-hidden-mobile">
                    <b-field>
                      <b-input placeholder="Naam" v-model="entry.name"></b-input>
                    </b-field>
                  </th>

                  <th class="is-hidden-mobile">
                    <div class="buttons right">
                      <b-button @click="add" icon-left="plus-circle" type="is-success"></b-button>
                    </div>
                  </th>
                </template>

                <template slot="empty">
                  <small>Gebruik de onderstaande balk om vlans toe te voegen.</small>
                </template>

              </b-table>
            </div>

            <b-field>
              <b-checkbox v-model="content.macro.options" native-value="afm_nieuwe_vlan" expanded>
                Netwerk voor technische toestellen (AFM) in <b>nieuwe</b> VLAN (niet aanbevolen) HFB zal VLAN en subnet
                toewijzen ifv de gevraagde aantallen in de opgegeven gebouwen.
              </b-checkbox>
            </b-field>
          </div>

          <b-field>
            <b-checkbox v-model="content.macro.options" native-value="ent_spec_virtual_network" expanded>
              Entiteit specifiek Virtueel Netwerk (Entiteit van de aanvrager)
            </b-checkbox>
          </b-field>
          <div class="box pl-5" v-if="content.macro.options.includes('ent_spec_virtual_network')">
            <b-field>
              <b-checkbox v-model="content.macro.options" native-value="new_ent_spec_virtual_network" expanded>
                Virtueel Netwerk bestaat nog niet en dient aangemaakt te worden
              </b-checkbox>
            </b-field>
          </div>

        </div>

      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
      >
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            Microsegmentatie
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-field>
            <b-checkbox v-model="content.micro.selected" expanded class="has-text-grey">
              Microsegmentatie op basis van SDN tag (roadmap contract 2022)
            </b-checkbox>
          </b-field>
          <div class="box pl-5" v-if="content.micro.selected">
            <b-field grouped>
              <b-field label="SGT-Prefix">
                <b-input v-model="content.micro.sgt_prefix"></b-input>
              </b-field>
              <b-field label="SGT waarde (1-100)" expanded>
                <b-input type="number" min="1" max="100" v-model="content.micro.sgt_value"></b-input>
              </b-field>
            </b-field>
          </div>
        </div>
      </b-collapse>
      <br>

      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
import BuildingSelector from "@/components/buildings/BuildingSelector";

export default {
  name: "segmentatie",
  components: {BuildingSelector},
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        macro: {
          options: [],
          vlan_data: []
        },
        micro: {
          selected: false,
          sgt_prefix: null,
          sgt_value: null
        }

      },

      // New entry
      entry: {
        building_code: null,
        vlan: null,
        name: null
      }

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._segmentatie) {
              Object.assign(this.content,JSON.parse(JSON.stringify(result._segmentatie)))
            }
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_segmentatie: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })

    },

    add() {
      this.content.macro.vlan_data.push(this.entry)
      this.entry = {}
    },
    remove(index) {
      if (index > -1) {
        this.content.macro.vlan_data.splice(index, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>