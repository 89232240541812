<template>
  <div class="container" style="min-height: 80vh; max-width: 1200px">
    <div class="columns">
      <div class="column is-3">
        <div class="box ">

          <b-steps v-model="currentstep" type="is-primary" :has-navigation="false" :vertical="true"
                   label-position="right" :destroy-on-hide="true" class="ml-4">
            <b-step-item :step="0" :clickable="true" label="Info" icon="account">
            </b-step-item>
            <b-step-item :step="1" :clickable="true" label="Locaties" icon="office-building-outline">
            </b-step-item>
            <b-step-item :step="2" :clickable="true" label="Koppeling" icon="plus-network">
            </b-step-item>
            <b-step-item :step="3" :clickable="true" label="Poorten" icon="plus-network">
            </b-step-item>
            <b-step-item :step="4" :clickable="true" label="Authenticatie" icon="key-variant">
            </b-step-item>
            <b-step-item :step="5" :clickable="true" label="Segmentatie" icon="segment">
            </b-step-item>
            <b-step-item :step="6" :clickable="true" label="Diensten" icon="dns">
            </b-step-item>
            <b-step-item :step="7" :clickable="true" label="Beheer" icon="remote-desktop">
            </b-step-item>
            <b-step-item :step="8" :clickable="true" label="Flows" icon="arrow-decision">
            </b-step-item>
            <b-step-item :step="9" :clickable="true" label="Toestel toevoegen" icon="check">
            </b-step-item>
          </b-steps>
        </div>

      </div>

      <div class="column">

        <div class="box" v-if="currentstep === 0">
          <div class="content article-body">
            <info-type :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 1">
          <div class="content article-body">
            <hoeveelheid-capacity :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 2">
          <div class="content article-body">
            <koppeling :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 3">
          <div class="content article-body">
            <lanpoorten :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 4">
          <div class="content article-body">
            <authenticatie :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 5">
          <div class="content article-body">
            <segmentatie :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 6">
          <div class="content article-body">
            <netwerkdiensten :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 7">
          <div class="content article-body">
            <beheer-op-afstand :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 8">
          <div class="content article-body">
            <informatiestromen :submission_id="submission_id" :device_id="device_id" v-on:next="next"/>
          </div>
        </div>

        <div class="box" v-if="currentstep === 9">
          <div class="content article-body">
            <p class="title pt-2">Toestel toevoegen? </p>
            <p>U staat op het punt om deze reekst toestellen aan uw aanvraag toe te voegen. U kan de ingevoegde
              informatie nog steeds wijzigen hierna.</p>
            <br>
            <b-button icon-left="plus-circle"
                      @click="$router.push({ name:'submission.onboarding.devices', params: { id: submission_id}})"
                      type="is-primary" outlined>Toevoegen
            </b-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InfoType from "@/components/toestellen/info-type";
import HoeveelheidCapacity from "@/components/toestellen/hoeveelheid-capacity";
import Koppeling from "@/components/toestellen/koppeling";
import Lanpoorten from "@/components/toestellen/lanpoorten";
import Authenticatie from "@/components/toestellen/authenticatie";
import Segmentatie from "@/components/toestellen/segmentatie";
import Netwerkdiensten from "@/components/toestellen/netwerkdiensten";
import BeheerOpAfstand from "@/components/toestellen/beheer-op-afstand";
import Informatiestromen from "@/components/toestellen/informatiestromen";


export default {
  name: "DeviceOnboarding",
  components: {
    Informatiestromen,
    BeheerOpAfstand,
    Netwerkdiensten,
    Segmentatie,
    Authenticatie,
    Lanpoorten,
    Koppeling,
    InfoType,
    HoeveelheidCapacity
  },

  data() {
    return {
      currentstep: 0

    }
  },

  mounted() {
    this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
        .then(response => {
          console.log(response.data)
          this.$store.dispatch('setTitle', {
            message: response.data.submission.name + ' - ' + response.data.name, // TODO Breadcrumb
            back: true,
            stack: [
              {message: response.data.submission.name},
              {message: response.data.name},
            ]
          })

        })
  },

  computed: {
    device_id() {
      return parseInt(this.$route.params.device_id)
    },
    submission_id() {
      return parseInt(this.$route.params.submission_id)
    },
  },
  methods: {

    next() {
      this.currentstep++
    },

    submitDevice() {
      this.$store.dispatch("sumbitDevice", this.id).then(() => {
        this.next()
      }).catch(error => {
        console.log(error)
        this.$buefy.toast.open({
          message: error.data.message,
          type: 'is-danger'
        })

      })
    },
  }
}
</script>

<style scoped>

.articles .content {
  line-height: 1.9;
  margin-left: 15px;
}


.promo-block .container {
  margin: 1rem 5rem;
}

.articles .content {
  line-height: 1.9;
  margin-left: 15px;
}

.article-title {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 2;
}

.article-body {
  padding: 1.5rem 1.5rem;
}

.promo-block .container {
  margin: 1rem 5rem;
}
</style>