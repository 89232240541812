<template>
  <div>
    <section>
      <p class="title is-4">Poorten op LAN Switch (alleen voor bedrade toestellen)</p>

      <p>
        Doorgaans zal elke bedraad toestel één poort op de switch in de SER behoeven. Gelieve het juiste aantal op te
        geven.
      </p>

      <b-field label="Hoeveel fysieke poorten per toestel?">
        <b-checkbox v-model="content.amount.options" native-value="one" expanded>
          één switchport (standaard)
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="content.amount.options" native-value="many" expanded>
          meer dan één:
        </b-checkbox>
      </b-field>
      <b-field>
        <b-input v-if="content.amount.options.includes('many')" v-model="content.amount.description"
                 placeholder="Geef op"></b-input>
      </b-field>

      <hr>

      <p>
        Gezien de standaardisatie op vlak van switch- en NAC configuraties, dienen geen switch ID’s en poortnummers etc.
        te worden uitgewisseld: poortsnelheden worden automatisch geselecteerd en VLAN dynamisch toegewezen. Indien
        technisch geen enkele vorm van authenticatie of negotiatie mogelijk is, kan bij wijze van uitzondering een
        specifieke switchconfiguratie worden opgezet.
      </p>

      <b-field>
        <b-checkbox v-model="content.custom_config.selected" expanded>
          Uitzonderlijke configuratie gewenst op de switchpoorten
        </b-checkbox>
      </b-field>

      <div v-if="content.custom_config.selected" class="">
        <b-field label="Wat?">
          <b-checkbox v-model="content.custom_config.options" native-value="100_full">
            vast 100 Mbit/s (Full D)
          </b-checkbox>
          <b-checkbox v-model="content.custom_config.options" native-value="100_half">
            vast 100 Mbit/s (Half D)
          </b-checkbox>
          <b-checkbox v-model="content.custom_config.options" native-value="1000">
            vast 1000 Mbit/s
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="content.custom_config.options" native-value="static" expanded>
            statische VLAN configuratie (<i>switchport mode access</i>), geen 8021x/MAB configuratie mogelijk
          </b-checkbox>
        </b-field>

        <b-table :data="content.custom_config.data">
          <b-table-column label="Gebouw" width="40%" v-slot="props">
            {{ props.row.building_code }}
          </b-table-column>
          <b-table-column label="Switch-ID" v-slot="props">
            {{ props.row.switch_id }}
          </b-table-column>
          <b-table-column label="Switch Port" numeric v-slot="props">
            {{ props.row.switch_port }}
          </b-table-column>
          <b-table-column label="VLAN" numeric v-slot="props">
            {{ props.row.vlan }}
          </b-table-column>
          <b-table-column>
            <template v-slot:header="{ column }">
              <download-csv v-if="content.custom_config.data.length > 0" :data="content.custom_config.data" name="custom_ports.csv" :label="column">
                <a>
                  <b-icon size="is-small" icon="download"></b-icon>
                </a>
              </download-csv>
            </template>

            <template v-slot="props">
              <b-button @click="remove(props.index)" icon-left="delete" type="is-danger"></b-button>
            </template>
          </b-table-column>

          <template slot="footer">
            <th class="is-hidden-mobile" style="width:40px">
              <b-field>
                <building-selector @select="building => (entry.building_code = building.code)"/>
              </b-field>
            </th>
            <th class="is-hidden-mobile">
              <b-field>
                <b-input placeholder="Switch-ID" v-model="entry.switch_id"></b-input>
              </b-field>
            </th>
            <th class="is-hidden-mobile">
              <b-field>
                <b-input placeholder="Switch-poort" v-model="entry.switch_port"></b-input>
              </b-field>
            </th>
            <th class="is-hidden-mobile">
              <b-field>
                <b-input placeholder="VLAN" v-model="entry.vlan"></b-input>
              </b-field>
            </th>

            <th class="is-hidden-mobile">
              <div class="buttons right">
                <b-button @click="add" icon-left="plus-circle" type="is-success"></b-button>
              </div>
            </th>
          </template>

          <template slot="empty">
            <small>Gebruik de onderstaande balk om switches toe te voegen.</small>
          </template>

        </b-table>


      </div>


      <br>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
import BuildingSelector from "@/components/buildings/BuildingSelector";

export default {
  name: "lanpoorten",
  components: {BuildingSelector},
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        amount: {
          options: [],
          description: null,
        },
        custom_config: {
          selected: false,
          options: [],
          data: []

        },
      },

      // New entry
      entry: {
        building_code: null,
        switch_id: null,
        switch_port: null,
        vlan: null,
      }


    }
  },

  mounted() {
    this.fetchData()
  },
  computed: {},

  methods: {


    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._lanpoorten) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._lanpoorten)))
            }
          })

    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_lanpoorten: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    },

    add() {
      this.content.custom_config.data.push(this.entry)
      this.entry = {}

    },

    remove(index) {
      if (index > -1) {
        this.content.custom_config.data.splice(index, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>