<template>
  <div>
    <section>
      <p class="title is-4">Beheer vanop afstand</p>

      <p>Hoe zullen de nieuwe toestellen worden beheerd?</p>

      <b-field>
        <b-radio v-model="content.option" native-value="not_required" expanded>
          Geen extra remote beheer benodigd
        </b-radio>
      </b-field>

      <div class="box pl-5" v-if="content.option === 'not_required'">
        <b-field>
          <b-checkbox v-model="content.extra_options" native-value="connecteert_zelf" expanded>
            Toestel connecteert zelf met hub/gateway/centrale of DC/Cloud xAAS dienst
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="content.extra_options" native-value="via_GID" expanded>
            Via GID ‘Endpoint Management’ (dienst in beheer vanuit VO Werkplekken)
          </b-checkbox>
        </b-field>
      </div>

      <b-field>
        <b-radio v-model="content.option" native-value="vo-pam" expanded>
          Via VO-PAM (vereist Cyberarc licentie)
        </b-radio>
      </b-field>

      <b-field>
        <b-radio class="has-text-grey" v-model="content.option" native-value="sna" expanded>
          Via Secure-Netwerk-Access via Netwerk-webportaal, clientless (roadmap HFB Netwerk Diensten)
        </b-radio>
      </b-field>

      <div class="box pl-5" v-if="content.option === 'sna'">
        <b-field>
          <b-checkbox v-model="content.extra_options" native-value="sna_https" expanded>
            toegang naar toestel op basis van HTTPS protocol (webinterface)
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="content.extra_options" native-value="sna_rdp" expanded>
            toegang naar toestel op basis van RDP protocol
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="content.extra_options" native-value="sna_ssh" expanded>
            toegang naar toestel op basis van SSH protocol
          </b-checkbox>
        </b-field>
      </div>

      <br>

      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>

export default {
  name: "beheer-op-afstand",
  components: {},
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {

        option: "",
        extra_options: [],

      },


    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._beheer_op_afstand) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._beheer_op_afstand)))
            }
          })
    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_beheer_op_afstand: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })

    },
  }
}
</script>

<style scoped>

</style>