<template>
  <div>
    <b-message type="is-primary is-light">
      <b>Hiertoe is – op zijn minst langs één kant van de communicatie een vast IP adres nodig (statisch of via DHCP
        reservatie) al dan niet dynamisch aangepast aan DNS (dynDNS)</b>
    </b-message>
    <b-field>
      <b-checkbox v-model="content.options" native-value="binnen_lokaal_segment" expanded>
        Bestaand gebruikersnetwerk (GID) op zowel berdraad als draadloos (SSID ‘Vlaanderen werk’), typisch voor
        gebruikerstoestellen. Standaard komen de toestellen in de Productie VLAN.
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox v-model="content.options" native-value="inter_vo" expanded>
        inter-VO building (zelfde toepassings-VLAN over de gebouwen geen, geen Firewall policies / informatiestromen
        benodigd)
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox v-model="content.options" native-value="andere_netwerken" expanded>
        van/naar/tussen andere Netwerken/VLAN/SGT, informatiestromen dienen opgegeven te worden. Voor Entiteiten
        aangesloten op het GID zal hiertoe de gemeenschappelijke User Firewall worden gehanteerd. Voor dedicated
        Firewalls in beheer van de Entiteit dient informatiestroom naar de Firewall van de Entiteit te worden opgegeven
        in het betreffende VO-DC (zie verder)
      </b-checkbox>
    </b-field>
    <div v-if="content.options.includes('andere_netwerken')" class="pl-5">
      <b-field>
        <b-checkbox v-model="content.options" native-value="andere_dedicated_fw" expanded>
          Het betreft een dedicated Firewalls in beheer van HFB
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="content.options" native-value="andere_unknown" expanded>
          Het betreft een dedicated Firewalls in beheer van HFB
        </b-checkbox>
      </b-field>

      <b-notification type="is-danger">
        TODO Add table
      </b-notification>
      <br>

    </div>
  </div>
</template>

<script>
export default {
  name: "inf-intra-vo",
  props: ["data"],

  data() {
    return {
      content: {
        options: []
      }
    }
  },

  watch: {
    data: function (newData) {
      Object.assign(this.content, newData)
    },
    content: {
      handler: function (newContent) {
        this.$emit('update', newContent)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>