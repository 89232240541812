<template>
  <div>
    <section>
      <p class="title is-4">Koppeling</p>

      <p>Hoe dienen de toestellen te worden gekoppeld?</p>

      <b-collapse
          class="card"
          ref="lan"
          animation="slide"
          v-model="content.lan.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.lan.selected"></b-checkbox>
            Wired – VO LAN
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-field label=" Standaard voorzien op alle switchpoorten in alle HFB gebouwen:">

            <div class="content">
              <dl style="list-style-type:none;">
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>UTP aansluiting, RJ45 connector</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>100 M/ 1000M poortsnelheden met autonegotiatie</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>POE en POE+</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>Quality of Service</span>
                </li>
              </dl>
            </div>
          </b-field>

          <b-field label="Niet standaard voorzien (en dus specifiek aan te vragen):">
            <b-checkbox v-model="content.lan.nonstandard_options" native-value="100_MM" expanded>
              100M multimode fiber toegang, niet standaard
            </b-checkbox>
            <b-checkbox v-model="content.lan.nonstandard_options" native-value="1000_MM" expanded>
              1000M multimode fiber toegang, niet standaard
            </b-checkbox>
            <b-checkbox v-model="content.lan.nonstandard_options" native-value="100_SM" expanded>
              100M singlemode fiber toegang, niet standaard
            </b-checkbox>
            <b-checkbox v-model="content.lan.nonstandard_options" native-value="1000_SM" expanded>
              100M singlemode fiber toegang, niet standaard
            </b-checkbox>
            <b-checkbox v-model="content.lan.nonstandard_options" native-value="UPOE" expanded>
              UPOE (POE++), niet standaard, niet overal aanwezig
            </b-checkbox>
          </b-field>

          <b-field label="Specifiek aan te vragen functionaliteit op het VO LAN netwerk:">
            <b-checkbox v-model="content.lan.multicast.selected" expanded>
              Multicasting
            </b-checkbox>
          </b-field>

          <div class="box pl-5" v-if="content.lan.multicast.selected">
            <b-notification type="is-primary is-light">Het gebruik van multicasting impliceert een specifieke
              configuratie en dient per usecase besproken en goedgekeurd te worden.
            </b-notification>
            <b-field label="Beschrijf de gewenste opzet (pim, rendez-vous, ..)">
              <b-input v-model="content.lan.multicast.description" type="textarea"></b-input>
            </b-field>
          </div>

          <hr>
          <p><b>Bedrading</b></p>

          <b-message type="is-primary is-light">
            Gezien de standaardisatie op vlak van switchconfiguraties dienen normaal gezien geen switchport ID’s en
            poortnummers te worden uitgewisseld, ook niet voor VLAN toewijzing.
          </b-message>

          <p class="text">
            Per verdieping (of per groep van verdiepingen) is een SER’s (Secondary Equipment Room) voorzien. Een SER
            behelst een klein datalokaal met netwerk componenten en patching benodigd voor het verdiep (of groep van
            verdiepingen). Er zou reeds bekabeling vanuit de SER tot op de ruimte of werkplek moeten voorzien zijn.
            Dit is gestructureerde bekabeling doorgaans van het type U/UTP Cat6a.
          </p>
          <b-field>
            <b-checkbox v-model="content.lan.bekabeling.selected" expanded>
              <p>Er is geen bekabeling voorzien in de gewenste ruimte en zou moeten worden aangevraagd !</p>
            </b-checkbox>
          </b-field>

          <div class="box pl-5" v-if="content.lan.bekabeling.selected">

            <b-field
                label="Beschrijf in detail de gewenste bekabeling (locatie/positie/aantal van gewenste RJ45-outlets">
              <b-input v-model="content.lan.bekabeling.description" type="textarea"></b-input>
            </b-field>
          </div>

          <b-image :src="require(`@/assets/bekabeling_1.png`)"></b-image>
          <b-image :src="require(`@/assets/bekabeling_2.png`)"></b-image>

        </div>
      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
          v-model="content.wifi.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.wifi.selected"></b-checkbox>
            VO Wi-Fi
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-field label="Standaard voorzien in alle HFB gebouwen:">

            <div class="content">
              <dl style="list-style-type:none;">
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>Voldoende WIFI dekking op alle (in gebruik zijnde) verdiepingen voor zowel data als voice-over-IP gebruik</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>2,4 &5 Ghz band (Wifi 6 on roadmap)</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-success" icon="check"></b-icon>
                  <span>Quality of Service op basis van WMM</span>
                </li>
                <li>
                  <b-icon class="pr-3" size="is-small" type="is-danger" icon="close"></b-icon>
                  <span>Beperkte outdoor dekking</span>
                  <b-tooltip
                      label="Outdoor gebruik? Zie ook beschrijving ‘Dekking buiten VO gebouwen’, hoofdstuk 2.3"
                      multilined>
                    <b-icon class="pl-3" size="is-small" type="is-dark" icon="information-outline"></b-icon>
                  </b-tooltip>
                </li>
              </dl>
            </div>
          </b-field>

          <b-field label="De volgende gepubliceerde SSID zijn standaard beschikbaar, selecteer de gewenste SSID:">
          </b-field>
          <b-checkbox v-model="content.wifi.options" native-value="VO_Werknemer" expanded>
            <b>Vlaanderen werkt</b> (VO Werknemer, VO WLAN) op basis van VO-NAC authenticatie, zie volgende paragraaf
          </b-checkbox>
          <b-checkbox v-model="content.wifi.options" native-value="VO_Bezoeker" expanded>
            <b>Vlaanderen verbindt</b> (VO Bezoeker, via laagdrempelig bezoekersportaal voor aanvraag tijdelijke
            wachtwoorden)
          </b-checkbox>
          <b-checkbox v-model="content.wifi.options" native-value="VO_Events" expanded>
            <b>Vlaanderen organiseert</b> (VO Events) op basis van gepubliceerd wachtwoord(en)
          </b-checkbox>
          <b-checkbox v-model="content.wifi.options" native-value="custom" expanded>
            Specifiek voor ‘technische’ toestellen zal een verborgen SSID kunnen gebruik worden (VO AFM) op basis van
            VO-NAC authenticatie, zie volgende paragraaf
          </b-checkbox>
        </div>

      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
          v-model="content.direct_wifi.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.direct_wifi.selected"></b-checkbox>
            Direct Wi-Fi
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-notification type="is-warning is-light">Het gebruik van ‘eigen’ WIFI wordt niet aanbevolen in de gebouwen
            van de VO gezien de mogelijke interferentie met de eigen VO WIFI AP’s op de 2,4 & 5 Ghz banden
          </b-notification>

          <b-field label="Toch kan het benodigd zijn voor een aantal specifieke protocollen:">
            <b-checkbox v-model="content.direct_wifi.options" native-value="miracast" expanded>
              Miracast
            </b-checkbox>
          </b-field>

          <div class="block" v-if="content.direct_wifi.options.includes('miracast')">
            <b-message type="is-info is-light">Vanaf Windows 10 is er de mogelijkheid om Miracast over Infrastructuur
              (LAN/WAN) te laten lopen. Het aandeel
              ‘Direct Wifi’ is dan slechts benodigd voor de aankondiging van de dienst zelf.
            </b-message>
          </div>

          <b-field>
            <b-checkbox v-model="content.direct_wifi.options" native-value="airplay" expanded>
              Apple Airplay (video streaming)
            </b-checkbox>
          </b-field>

          <b-field>
            <b-checkbox v-model="content.direct_wifi.options" native-value="chromecast" expanded>
              Google Chromecast (video streaming)
            </b-checkbox>
          </b-field>

        </div>

      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
          v-model="content.device_ap.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title pb-0">
            <b-checkbox :readonly="true" disabled :value="content.device_ap.selected"></b-checkbox>
            Toestel fungeert zelf als WIFI AP
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          Hier zal het toestel zelf fungeren als access point (vb Barco Clickshare).
          <b-notification type="is-warning is-light">Het gebruik van ‘eigen’ WIFI wordt niet aanbevolen in de gebouwen
            van de VO gezien de mogelijke interferentie met de eigen VO WIFI AP’s op de 2,4 & 5 Ghz banden
          </b-notification>
        </div>

      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
          v-model="content.lpwan.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title has-text-grey pb-0">
            <b-checkbox :readonly="true" disabled :value="content.lpwan.selected"></b-checkbox>
            LPWAN
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          Low Power WAN (vb LoRa, Sigfox, LTE-M, NB- IoT, ..) wordt momenteel nog niet aangeboden door HFB (roadmap
          contract 2022)
          NB-IoT (op basis van SIM kaarten) valt niet onder Netwerken maar onder Telecommunicatie.
        </div>

      </b-collapse>

      <b-collapse
          class="card"
          animation="slide"
          v-model="content.lrwpan.selected">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header">

          <p class="card-header-title has-text-grey pb-0">
            <b-checkbox :readonly="true" disabled :value="content.lrwpan.selected"></b-checkbox>
            LR-WPAN
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content">
          LR-WPANs (Low Rate- Wireless Personal Area Networks, 802.15.4) - zoals bijv. Zigbee - wordt momenteel nog niet
          aangeboden door HFB maar zal in de toekomst vermoedelijk op niveau van wireless AP kunnen worden ingericht
          (roadmap contract 2022)
        </div>

      </b-collapse>


      <br>


      <b-field horizontal class="has-text-right"><!-- Label left empty for spacing -->
        <p class="control">
          <button @click="submit" class="button is-primary">
            Volgende
          </button>
        </p>
      </b-field>

      <br>

    </section>
  </div>

</template>

<script>
export default {
  name: "koppeling",
  props: ['device_id', 'submission_id'],

  data() {
    return {
      content: {
        lan: {
          selected: false,
          nonstandard_options: [],
          multicast: {
            selected: false,
            description: null,
          },
          bekabeling: {
            selected: false,
            description: null,
          }
        },
        wifi: {
          selected: false,
          options: []
        },
        direct_wifi: {
          selected: false,
          options: []
        },
        device_ap: {
          selected: false,
        },
        lpwan: {
          selected: false,
        },
        lrwpan: {
          selected: false,
        }

      },


    }
  },

  mounted() {
    this.fetchData()
  },
  computed: {},

  methods: {


    fetchData() {
      this.$store.dispatch("fetchDeviceDetails", {submission_id: this.submission_id, device_id: this.device_id})
          .then(response => {
            let result = response.data.data
            if (result._3_koppeling) {
              Object.assign(this.content, JSON.parse(JSON.stringify(result._3_koppeling)))
            }
          })

    },

    submit() {
      this.$store.dispatch("storeDeviceData", {
        'submission_id': this.submission_id,
        'device_id': this.device_id,
        'data': {_3_koppeling: this.content}
      }).then(() => {
        this.$buefy.toast.open({
          message: 'Saved in backend',
          type: 'is-success'
        })
        this.$emit("next")
      })


    },
  }
}
</script>

<style scoped>

</style>