<template>
  <div>
    <b-message class="is-info is-light">
      Voor Entiteiten aangesloten op het GID zal hiertoe de gemeenschappelijke User Firewall worden gehanteerd. Indien
      Firewall/Proxy in eigen beheer - Entiteit is zelf verantwoordelijk voor de policies – dient informatiestroom naar
      de Firewall van de Entiteit te worden opgegeven in het betreffende VO-DC (zie verder).
      Verbindingen van en naar het Internet dienen steeds te verlopen via een mitigatie component. Dit kan een proxy of
      gateway zijn Naar welke destinaties dient te worden geconnecteerd?

    </b-message>

    <b-field>
      <b-checkbox v-model="content.options" native-value="vo_proxy" expanded>
        Any destination (vb kiosk browsing) via VO proxy
      </b-checkbox>
    </b-field>
    <div v-if="content.options.includes('vo_proxy')" class="pl-5 pb-5">
      <b-field>
        <b-radio v-model="content.instelbare_proxy" :native-value="true" expanded>
          Het toestel ondersteunt instelbare proxy settings
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="content.instelbare_proxy" :native-value="false" expanded>
          Het toestel ondersteunt geen instelbare proxy settings
        </b-radio>
      </b-field>
    </div>

    <b-field>
      <b-checkbox v-model="content.options" native-value="fixed_ips" expanded>
        vaste URL(s) of public IP’s (IAAS/PAAS/SAAS), informatiestromen op te geven (niet indien Firewall in eigen
        beheer)
      </b-checkbox>
    </b-field>
    <div v-if="content.options.includes('fixed_ips')" class="pl-5">
      <b-field>
        <b-checkbox v-model="content.options" native-value="fixed_ips_assistance_req" expanded>
          de informatiestromen zijn niet gekend en wens hierbij assistentie
        </b-checkbox>
      </b-field>

      <b-table :data="content.fixed_ips.data">

        <b-table-column label="Public IP/URL" v-slot="props">
          {{ props.row.url }}
        </b-table-column>

        <b-table-column label="Protocol/Poort" v-slot="props">
          {{ props.row.port }}
        </b-table-column>

        <b-table-column label="Beschrijving" v-slot="props">
          {{ props.row.descr }}
        </b-table-column>

        <b-table-column v-slot="props">
          <b-button @click="removeOpt(props.index)" icon-left="delete" type="is-danger"></b-button>
        </b-table-column>

        <template slot="empty">
          <small>Gebruik de onderstaande balk om mac adressen toe te voegen.</small>
        </template>

        <template slot="footer">
          <th class="is-hidden-mobile">
            <b-field>
              <b-input placeholder="Public IP/URL" v-model="option.url"></b-input>
            </b-field>
          </th>
           <th class="is-hidden-mobile">
            <b-field>
              <b-input placeholder="Protocol/Poort" v-model="option.port"></b-input>
            </b-field>
          </th>
           <th class="is-hidden-mobile">
            <b-field>
              <b-input placeholder="Beschrijving" v-model="option.descr"></b-input>
            </b-field>
          </th>
          <th class="is-hidden-mobile">
            <div class="buttons right">
              <b-button @click="addOpt" icon-left="plus-circle" type="is-success"></b-button>
            </div>
          </th>
        </template>
      </b-table>

    </div>


  </div>
</template>

<script>
export default {
  name: "inf-internet",
  props: ["data"],

  data() {
    return {
      content: {
        options: [],
        instelbare_proxy: false,
        fixed_ips: {
          data: []
        }
      },
      option: {
        url: "",
        port: "",
        descr: "",
      }
    }
  },

  watch: {
    data: function (newData) {
      Object.assign(this.content, newData)
    },
    content: {
      handler: function (newContent) {
        this.$emit('update', newContent)
      },
      deep: true
    }
  },
  methods: {
     addOpt() {
      this.content.fixed_ips.data.push(this.option)
      this.option = {}
    },
    removeOpt(index) {
      if (index > -1) {
        this.content.fixed_ips.data.splice(index, 1);
      }
    }
  }
}

</script>

<style scoped>

</style>